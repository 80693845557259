import { h } from 'preact'
import { useEffect, useState, useRef, useCallback } from 'preact/hooks'
import { format, previousMonday, startOfWeek } from 'date-fns'
import { PreviousMonthButton } from './WeekPicker/PreviousMonthButton'
import { NextMonthButton } from './WeekPicker/NextMonthButton'
import { WeekRow } from './WeekPicker/WeekRow'
import { Turbo } from '@hotwired/turbo-rails'

const DAY_NAMES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const MONTH_NAMES: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const DATE_FORMAT = 'dd MMM yy'

function formatDate(date: Date) {
  return format(date, DATE_FORMAT)
}

function groupArray<T>(array: T[], num: number): T[][] {
  const group: T[][] = []

  for (let i = 0; i < array.length; i += num) {
    group.push(array.slice(i, i + num))
  }

  return group
}

function useOutsideClick(ref: any, onClickOut: () => void, deps: any[] = []) {
  useEffect(() => {
    const onClick = ({ target }: any) => {
      !ref?.contains(target) && onClickOut?.()
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, deps)
}

interface Props {
  date?: Date
}

export interface DayObject {
  day: number
  type: 'blank' | 'normal'
}

export function WeekPicker({ date: initialDate = new Date() }: Props) {
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(initialDate)
  const [month, setMonth] = useState(initialDate.getMonth())
  const [year, setYear] = useState(initialDate.getFullYear())
  const [blankDaysInMonth, setBlankDaysInMonth] = useState<number[]>([])
  const [daysInMonth, setDaysInMonth] = useState<number[]>([])

  const containerRef = useRef<HTMLDivElement>()
  const today = useRef(new Date()).current

  const formattedDate = formatDate(date)
  const allDays: DayObject[] = [
    ...blankDaysInMonth.map(day => ({
      day,
      type: 'blank',
    })),
    ...daysInMonth.map(day => ({
      day,
      type: 'normal',
    })),
  ]
  const groupedDays = groupArray(allDays, 7)
  const atMaxMonth = today.getFullYear() === year && today.getMonth() === month

  useEffect(() => {
    document.addEventListener('keyup', closeWithKeyboard)

    return () => {
      document.removeEventListener('keyup', closeWithKeyboard)
    }
  }, [])

  useOutsideClick(
    containerRef.current,
    () => {
      setOpen(false)
    },
    [containerRef.current]
  )

  const toggleOpen = useCallback(() => {
    setOpen(currentValue => !currentValue)
  }, [])

  const showCalendar = useCallback(() => {
    setOpen(true)
  }, [])

  function datePickerCalculateDays() {
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate()
    // find where to start calendar day of week
    const dayOfWeek = new Date(year, month).getDay()
    const dayIndex = dayOfWeek === 0 ? 6 : dayOfWeek - 1
    const blankDaysArray: number[] = []

    for (var i = 1; i <= dayIndex; i++) {
      blankDaysArray.push(i)
    }

    const daysArray: number[] = []
    for (var i = 1; i <= totalDaysInMonth; i++) {
      daysArray.push(i)
    }

    setBlankDaysInMonth(blankDaysArray)
    setDaysInMonth(daysArray)
  }

  useEffect(() => {
    datePickerCalculateDays()
  }, [date])

  useEffect(() => {
    datePickerCalculateDays()
  }, [month])

  const closeWithKeyboard = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setOpen(false)
    }
  }, [])

  const selectThisWeek = useCallback(() => {
    const today = new Date()
    const monday = startOfWeek(today, { weekStartsOn: 1 })

    dateSelected(monday)
  }, [])

  const selectLastWeek = useCallback(() => {
    const today = new Date()
    const monday = startOfWeek(today, { weekStartsOn: 1 })
    const lastMonday = previousMonday(monday)

    dateSelected(lastMonday)
  }, [])

  function dateSelected(selectedDate: Date): void {
    setDate(selectedDate)
    setOpen(false)

    const url = new URL(window.location.href)
    url.searchParams.set('week_commencing', format(selectedDate, 'yyyy-MM-dd'))
    Turbo.visit(url, {
      action: 'replace',
    })
  }

  function datePickerDayClicked(day: number) {
    const selectedDate = new Date(year, month, day)

    setDate(selectedDate)
    setOpen(false)

    const url = new URL(window.location.href)
    url.searchParams.set('week_commencing', format(selectedDate, 'yyyy-MM-dd'))
    Turbo.visit(url, {
      action: 'replace',
    })
  }

  function datePickerPreviousMonth() {
    if (month === 0) {
      setYear(year - 1)
      setMonth(11)
    } else {
      setMonth(month - 1)
    }
  }

  function datePickerNextMonth() {
    if (atMaxMonth) return

    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }

  return (
    <div ref={containerRef} class="container block mb-1">
      <div class="w-full">
        <label
          for="week-commencing"
          class="block mb-1 text-left text-sm/6 font-medium text-neutral-500"
        >
          Week commencing
        </label>
        <div class="relative">
          <input
            id="week-commencing"
            type="text"
            onFocus={showCalendar}
            value={formattedDate}
            class="flex w-full h-10 px-3 py-2 text-sm bg-white dark:bg-neutral-700 border rounded-md text-neutral-600 dark:text-neutral-300 border-gray-300 dark:border-neutral-600 ring-offset-background placeholder:text-neutral-400 dark:placeholder:text-neutral-600 focus:border-gray-300 dark:focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 disabled:cursor-not-allowed disabled:opacity-50"
            placeholder="Select date"
            readonly
          />
          <div
            onClick={toggleOpen}
            class="absolute top-0 right-0 px-3 py-2 cursor-pointer text-gray-400 dark:text-neutral-500 hover:text-gray-500 dark:hover:text-neutral-400"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </div>
          {open && (
            <div class="absolute top-0 left-0 max-w-lg p-4 mt-12 antialiased bg-white dark:bg-neutral-800 border rounded-lg shadow w-[17rem] border-gray-200/70 dark:border-neutral-700">
              <div class="flex items-center justify-between mb-2">
                <div>
                  <span class="text-lg font-bold text-gray-800 dark:text-neutral-300">
                    {MONTH_NAMES[month]}
                  </span>
                  <span class="ml-1 text-lg font-normal text-gray-600 dark:text-neutral-500">
                    {year}
                  </span>
                </div>
                <div>
                  <PreviousMonthButton onPress={datePickerPreviousMonth} />
                  <NextMonthButton
                    onPress={datePickerNextMonth}
                    disabled={atMaxMonth}
                  />
                </div>
              </div>
              <div class="grid grid-cols-7 mb-3">
                {DAY_NAMES.map(day => (
                  <div key={day} class="px-0.5">
                    <div class="text-xs font-medium text-center text-gray-800 dark:text-neutral-400">
                      {day}
                    </div>
                  </div>
                ))}
              </div>
              {groupedDays.map(group => (
                <WeekRow
                  key={group}
                  date={date}
                  week={group}
                  month={month}
                  year={year}
                  onClick={datePickerDayClicked}
                />
              ))}
              <div class="flex space-x-2 mt-2">
                <button
                  type="button"
                  class="button clear-btn text-gray-900 dark:text-white bg-white dark:bg-neutral-700 border border-gray-300 dark:border-neutral-600 hover:bg-gray-100 dark:hover:bg-neutral-600 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2 text-center w-1/2"
                  onClick={selectLastWeek}
                >
                  Last week
                </button>
                <button
                  type="button"
                  class="button today-btn text-white bg-primary-700 dark:bg-primary-600 hover:bg-primary-800 dark:hover:bg-primary-700 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2 text-center w-1/2"
                  onClick={selectThisWeek}
                >
                  This week
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
